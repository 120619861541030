<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Configuration" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Application name *"
                  v-model="applicationName"
                  :error="!!errors.app_name"
                  :error-messages="errors.app_name"
                  @input="delete errors.app_name"
                />
                <va-input
                  label="Scripts for head end"
                  v-model="scriptEndHead"
                  type="textarea"
                  :error="!!errors.app_script_head_end"
                  :error-messages="errors.app_script_head_end"
                  @input="delete errors.app_script_head_end"
                />
                <va-input
                  label="Scripts for body end"
                  v-model="scriptsBodyEnd"
                  type="textarea"
                  :error="!!errors.app_script_body_end"
                  :error-messages="errors.app_script_body_end"
                  @input="delete errors.app_script_body_end"
                />
                <va-input
                  label="Micromarking script"
                  v-model="micromarkingScript"
                  type="textarea"
                  :error="!!errors.app_micromarking"
                  :error-messages="errors.app_micromarking"
                  @input="delete errors.app_micromarking"
                />
                <va-input
                  label="Google Maps API Key *"
                  v-model="googleApiKey"
                  :error="!!errors.app_map_api_key"
                  :error-messages="errors.app_map_api_key"
                  @input="delete errors.app_map_api_key"
                />
                <va-input
                  label="Site Domain Url *"
                  v-model="appUrl"
                  :error="!!errors.app_frontend_domain_url"
                  :error-messages="errors.app_frontend_domain_url"
                  @input="delete errors.app_frontend_domain_url"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      applicationName: '',
      googleApiKey: '',
      scriptEndHead: '',
      scriptsBodyEnd: '',
      micromarkingScript: '',
      appUrl: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        app_name: this.applicationName,
        app_script_head_end: this.scriptEndHead,
        app_script_body_end: this.scriptsBodyEnd,
        app_micromarking: this.micromarkingScript,
        app_map_api_key: this.googleApiKey,
        app_frontend_domain_url: this.appUrl,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    submit () {
      axios.put(`${process.env.VUE_APP_1LK_API_URL}/admin/site-config?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_1LK_API_URL}/admin/site-config/validate?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_1LK_API_URL}/admin/site-config?lang=${this.locale}`)
        .then(response => {
          // console.log(response.data)
          this.applicationName = response.data.app_name
          this.scriptEndHead = response.data.app_script_head_end
          this.scriptsBodyEnd = response.data.app_script_body_end
          this.micromarkingScript = response.data.app_micromarking
          this.googleApiKey = response.data.app_map_api_key
          this.appUrl = response.data.app_frontend_domain_url
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
